<template>
  <div class="container">
    <div class="login-form d-flex align-items-center">
      <div class="card">
        <div class="card-header">
          <h3>Log In</h3>
        </div>
        <div class="card-body">
          <form>
            <b-alert variant="danger" show dismissible fade v-if="errMessage">{{
              errMessage
            }}</b-alert>

            <div class="input-group form-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <font-awesome-icon icon="fa-solid fa-user" />
                </span>
              </div>
              <b-form-input
                required
                type="text"
                class="form-control"
                placeholder="User name"
                v-model="form.username"
              />
            </div>
            <div class="input-group form-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <font-awesome-icon icon="fa-solid fa-lock" />
                </span>
              </div>
              <b-form-input
                required
                type="password"
                class="form-control"
                placeholder="Password"
                v-model="form.password"
              />
            </div>

            <div class="form-group">
              <button
                class="btn btn-primary float-right login-button"
                @click.prevent.stop="onLogin"
                :disabled="!form.username || !form.password"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      errMessage: "",
    };
  },
  mounted() {
    const authenticate = sessionStorage.getItem("authenticate");
    if (authenticate) {
      this.$router.push("/admin");
    }
  },
  methods: {
    ...mapActions("user", ["doLogin"]),
    async onLogin() {
      try {
        const res = await this.doLogin({
          username: this.form.username,
          password: this.form.password,
        });
        sessionStorage.setItem("authenticate", res.token);

        this.$router.push("/admin");
      } catch (err) {
        this.errMessage = "Fail to Login!";
      }
    },
  },
};
</script>
<style lang="scss">
.container {
  height: 100vh !important;
  .btn.btn-primary {
    background-color: #00aeef !important;
    border: none !important;
  }
  .login-form {
    min-width: 300px;
    max-width: 600px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .card {
    width: 100%;
  }
  @media only screen and (max-width: 992px) {
    .login-form {
      min-width: 80%;
      max-width: 95%;
    }
  }
}
</style>